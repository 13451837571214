<template>
  <vx-card title="Customer Deposit Settlement">
    <div class="flex flex-col gap-5">
      <div class="vx-col sm:w-1/3 w-full whitespace-no-wrap">
        <!-- <div class="flex flex-col justify-between gap-2">
          <span>Territories</span>
          <multiselect
            v-model="selectedTerritories"
            :options="
              !territoryOptions || territoryOptions.length == 0
                ? []
                : territoryOptions
            "
            :multiple="true"
            :allow-empty="true"
            :group-select="false"
            :max-height="160"
            :limit="3"
            placeholder="Type Territory"
            track-by="TerritoryID"
            label="TerritoryName"
            :custom-label="queryTerritory"
            @input="onTeritoryChange"
          >
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">
                  ({{ props.option.TerritoryCode }}),
                  {{ props.option.TerritoryName }}</span
                >
              </div>
            </template>
            <template slot="tag" slot-scope="props">
              <div class="multiselect__tag">
                <span>{{ props.option.TerritoryName }}</span>
                <i
                  class="multiselect__tag-icon"
                  @click.prevent
                  @mousedown.prevent.stop="props.remove(props.option, $event)"
                />
              </div>
            </template>
          </multiselect>
        </div> -->
        <div class="flex flex-col justify-between gap-2">
          <span>Supplier</span>
          <multiselect
            class="selectExample w-full"
            v-model="selectSupplier.value"
            :options="selectSupplier.option"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            select-label=""
            deselect-label=""
            :searchable="selectSupplier.searchabel"
            :internal-search="selectSupplier.internal_search"
            :loading="selectSupplier.loading"
            :max-height="selectSupplier.max_height"
            :limit="selectSupplier.limit"
            :disabled="selectSupplier.disabled"
            v-validate="selectSupplier.validate"
            :name="selectSupplier.name"
            placeholder="Type to search"
            @search-change="handlerSearchSupplier"
            :custom-label="selectSupplier.label"
          />
        </div>
      </div>
      <div class="vx-col sm:w-2/3 w-full whitespace-no-wrap flex mt-5">
        <vs-button @click="onFilter">Filter</vs-button>
      </div>
      <vs-tabs v-model="currentTab" class="mt-6">
        <vs-tab label="New">
          <div class="con-tab-ejemplo">
            <open
              :territoryIDs="territoryIDs"
              :dcIDs="dcIDs"
              :selectedCustomer="selectedCustomer"
              :selectedDate="selectedDate"
              :isQueryDone="isQueryDone"
              :invoiceCodes="invoiceCodeTags"
              :isFilter="isFilter"
              :selectSupplier="selectSupplier"
            />
          </div>
        </vs-tab>
        <vs-tab label="Settled">
          <div class="con-tab-ejemplo">
            <settled
              :territoryIDs="territoryIDs"
              :selectedCustomer="selectedCustomer"
              :selectedDate="selectedDate"
              :invoiceExchangeStatus="invoiceExchangeStatus"
              :isQueryDone="isQueryDone"
              :isFilter="isFilter"
              :selectSupplier="selectSupplier"
            />
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </vx-card>
</template>

<script>
import open from "./open/index.vue";
import settled from "./settled/index.vue";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import debounce from "debounce";
export default {
  components: {
    open,
    settled,
    "date-range-picker": DateRangePicker,
  },

  data() {
    return {
      url_supplier: "/api/v1/master/suppliers",
      currentTab: 0,
      territoryOptions: [],
      selectedTerritories: [],
      territoryIDs: [],
      dcOptions: [],
      selectedDC: [],
      dcIDs: [],
      customerOptions: [],
      selectedCustomer: {},
      isQueryDone: false,
      dcOptionAll: {
        ID: 0,
        name: "All",
        classification: "All",
      },
      territoryOptionAll: {
        ID: 0,
        TerritoryCode: "All",
        TerritoryName: "All",
      },
      customerOptionAll: {
        ID: 0,
        code: "All",
        name: "All",
      },
      selectedDate: {},
      invoiceExchangeStatus: 1,
      invoiceCodeTags: [],
      invoiceCodeOptionAll: {
        id: 0,
        code: "All",
      },
      invoiceCodeOptions: [],
      isFilter: false,
      debounceFunction: null,
      selectSupplier: {
        name: "supplier",
        loading: false,
        searchabel: true,
        internal_search: false,
        validate: "required",
        max_height: 200,
        disabled: false,
        limit: 5,
        option: [
          {
            id: 0,
            code: "All",
            name: "-",
          },
        ],
        value: {
          id: 0,
          code: "All",
          name: "All",
        },
        label: ({ code, name }) => {
          return `${code} - ${name}`;
        },
      },
    };
  },
  mounted() {
    this.territoryOptions = this.$store.state.operatingUnit.filter.territory;
    this.territoryIDs =
      this.$store.getters["operatingUnit/getSelectedTerritoryId"];
    this.selectedTerritories = [this.territoryOptions[0]];
    this.selectedDate = {
      startDate: null,
      endDate: null,
    };
    this.selectedCustomer = this.customerOptionAll;
    // this.getOptions();
    let supplier = this.getOptionSupplier();
    supplier.then((r) => {
      if (r.code != 200) {
        this.$vs.notify({
          color: "danger",
          title: r.code,
          text: r.message,
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
      } else {
        this.selectSupplier.option = this.dataSuppliers;
        this.selectSupplier.option.unshift({
          id: 0,
          code: "All",
          name: "All",
        });
      }
    });
  },
  watch: {
    currentTab() {
      this.isQueryDone = false;
      this.resetDatePicker();
      this.resetCustomer();
      this.resetDC();
      this.resetTerritory();
      this.resetInvoiceExchangeStatus();
      this.isQueryDone = true;
      console.log("releasing");
    },
    selectedTerritories() {
      // this.isQueryDone = false;
      // this.$vs.loading();
      // this.getInvoiceFilter()
      //   .then((r) => {
      //     if (r.code === 200) {
      //       this.invoiceCodeOptions = r.data;
      //     } else {
      //       this.invoiceCodeOptions = [];
      //     }
      //     this.isQueryDone = true;
      //     this.$vs.loading.close();
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //     // this.isQueryDone = true;
      //     this.$vs.loading.close();
      //   });
    },
    selectedDate() {
      // this.isQueryDone = false;
      // this.$vs.loading();
      // this.getInvoiceFilter()
      //   .then((r) => {
      //     if (r.code === 200) {
      //       this.invoiceCodeOptions = r.data;
      //     } else {
      //       this.invoiceCodeOptions = [];
      //     }
      //     // this.isQueryDone = true;
      //     this.$vs.loading.close();
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //     // this.isQueryDone = true;
      //     this.$vs.loading.close();
      //   });
    },
    selectedCustomer() {
      // this.isQueryDone = false;
      // this.$vs.loading();
      // this.getInvoiceFilter()
      //   .then((r) => {
      //     if (r.code === 200) {
      //       this.invoiceCodeOptions = r.data;
      //     } else {
      //       this.invoiceCodeOptions = [];
      //     }
      //     // this.isQueryDone = true;
      //     this.$vs.loading.close();
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //     // this.isQueryDone = true;
      //     this.$vs.loading.close();
      //   });
    },
  },
  methods: {
    handlerSearchSupplier(s) {
      if (s != "") {
        this.selectSupplier.loading = true;
        this.selectSupplier.search = s;
        let supplier = this.getOptionSupplier(s);
        supplier.then((r) => {
          if (this.selectSupplier.search == s) {
            this.selectSupplier.option = [];
            for (var supp = 0; supp < r.data.records.length; supp++) {
              this.selectSupplier.option.push({
                id: r.data.records[supp].ID,
                code: r.data.records[supp].Code,
                name: r.data.records[supp].Name,
              });
            }
            this.selectSupplier.loading = false;
          }
        });
      } else {
        this.selectSupplier.option = this.allDataSuppliers;
      }
    },

    getOptionSupplier(s = "") {
      return new Promise((resolve, reject) => {
        this.$http
          .get(this.url_supplier, {
            params: {
              search: s,
              length: 10,
              page: 1,
            },
          })
          .then((resp) => {
            if (resp.code == 200) {
              if (s == "") {
                this.dataSuppliers = [];
                for (var supp = 0; supp < resp.data.records.length; supp++) {
                  this.dataSuppliers.push({
                    id: resp.data.records[supp].ID,
                    code: resp.data.records[supp].Code,
                    name: resp.data.records[supp].Name,
                  });
                }
                this.alldataSuppliers = this.dataSuppliers;
              }
              resolve(resp);
            } else {
              this.$vs.loading.close();
              reject(resp);
              // this.$router.push("/");
            }
          });
      });
    },
    async getOptions() {
      try {
        this.$vs.loading();
        let resp = await this.getCustomerOptions();
        if (resp.code === 200) {
          this.customerOptions = resp.data;
        } else {
          this.customerOptions = [];
        }

        resp = await this.getInvoiceFilter();
        if (resp.code === 200) {
          this.invoiceCodeOptions = resp.data;
        } else {
          this.invoiceCodeOptions = [];
        }

        console.log(this.customerOptions);

        // this.isQueryDone = true;
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        console.log(e);
      }
    },
    getDistributionChannel() {
      return this.$http.get("/api/v1/master/customer-category-all");
    },
    getCustomerOptions(search = "") {
      return this.$http.get("/api/v1/invoice-exchange/customers", {
        params: {
          search,
        },
      });
    },
    getInvoiceFilter(search = "") {
      return this.$http.get("/api/v1/invoice/exchange/filter", {
        params: {
          search,
          length: 50,
          page: 1,
          order: "desc",
          sort: "id",
          status_in: [1, 2, 3],
          territory_ids: this.territoryIDs,
          customer_name:
            !this.selectedCustomer.name || this.selectedCustomer.name == "All"
              ? undefined
              : this.selectedCustomer.name,
          customer_code:
            !this.selectedCustomer.code || this.selectedCustomer.code == "All"
              ? undefined
              : this.selectedCustomer.code,
          start_date: !this.selectedDate.startDate
            ? undefined
            : this.dateFormatApi(this.selectedDate.startDate),
          end_date: !this.selectedDate.endDate
            ? undefined
            : this.dateFormatApi(this.selectedDate.endDate),
        },
      });
    },
    queryTerritory({ TerritoryCode, TerritoryName }) {
      return `(${TerritoryCode}), ${TerritoryName}`;
    },
    queryCustomer({ code, name }) {
      return `(${code}), ${name}`;
    },
    dateFormatApi(value) {
      return moment(value).format("YYYY-MM-DD");
    },
    onCustomerSearch(value) {
      if (this.debounceFunction) {
        this.debounceFunction.clear();
      }
      this.debounceFunction = debounce(() => {
        this.$vs.loading();
        this.getCustomerOptions(value)
          .then((r) => {
            if (r.code === 200) {
              this.customerOptions = r.data;
            }
            this.$vs.loading.close();
          })
          .catch((e) => {
            console.log(e);
            this.$vs.loading.close();
          });
      }, 500);
      this.debounceFunction();
    },
    onTeritoryChange(value) {
      if (value.length === 0) {
        this.selectedTerritories = [this.territoryOptionAll];
        this.territoryIDs =
          this.$store.getters["operatingUnit/getSelectedTerritoryId"];
        return;
      }

      if (value[0].ID === 0) {
        value.shift();
        this.selectedTerritories = value;
      } else if (value.some((ou) => ou.ID === 0)) {
        this.selectedTerritories = [this.territoryOptionAll];
        this.territoryIDs =
          this.$store.getters["operatingUnit/getSelectedTerritoryId"];
        return;
      } else {
        this.selectedTerritories = value;
      }
      this.territoryIDs = this.selectedTerritories.map((ou) => ou.TerritoryID);
    },
    onDCChange(value) {
      if (value.length === 0) {
        const allValue = [this.dcOptionAll];
        this.selectedDC = allValue;
        this.dcIDs = this.dcOptions.map((dc) => dc.ID);
        return;
      }

      if (value[0].ID === 0) {
        value.shift();
        this.selectedDC = value;
      } else if (value.some((dc) => dc.ID === 0)) {
        this.selectedDC = [this.dcOptionAll];
        this.dcIDs = this.dcOptions.map((dc) => dc.ID);
        return;
      } else {
        this.selectedDC = value;
      }
      this.dcIDs = this.selectedDC.map((dc) => dc.ID);
    },
    dateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("dddd, MMMM Do YYYY");
      }
      return a;
    },
    resetDatePicker() {
      this.selectedDate = {
        startDate: null,
        endDate: null,
      };
    },
    resetCustomer() {
      this.selectedCustomer = this.customerOptionAll;
    },
    resetDC() {
      this.selectedDC = [this.dcOptionAll];
    },
    resetTerritory() {
      this.selectedTerritories = [this.territoryOptionAll];
    },
    resetInvoiceExchangeStatus() {
      this.invoiceExchangeStatus = 2;
    },
    resetTag() {
      this.invoiceCodeTags = [];
    },
    onTagInput(tag) {
      console.log("here is the tag");
      const tagObject = {
        id: this.invoiceCodeTags.length + 1,
        code: tag,
      };
      this.invoiceCodeTags.push(tagObject);
    },
    async onAddTag(tag) {
      console.log(tag);
      const tags = tag.replace(/\s+/g, "").trim().split(";");

      if (tag.includes(";")) {
        console.log("contain ;");
        console.log(tags.length);
        for (let i = 0; i < tags.length; i++) {
          const tagObject = {
            id: this.invoiceCodeTags.length + 1,
            code: tags[i],
          };
          this.invoiceCodeTags.push(tagObject);
        }
        // this.invoiceCodeTags.pop();
        this.$refs.tagInput.deactivate();
      } else {
        if (this.debounceFunction) {
          this.debounceFunction.clear();
        }
        this.debounceFunction = debounce(() => {
          this.$vs.loading();
          this.getInvoiceFilter(tag)
            .then((r) => {
              if (r.code === 200) {
                this.invoiceCodeOptions = r.data;
              } else {
                this.invoiceCodeOptions = [];
              }
              this.$vs.loading.close();
            })
            .catch((e) => {
              console.log(e);
              this.$vs.loading.close();
            });
        }, 500);
      }
    },
    onFilter() {
      this.isFilter = true;
      this.$nextTick(() => {
        this.isFilter = false;
      });
      // this.isQueryDone = false;
      // this.$vs.loading();
      // this.getInvoiceFilter()
      //   .then((r) => {
      //     if (r.code === 200) {
      //       this.invoiceCodeOptions = r.data;
      //     } else {
      //       this.invoiceCodeOptions = [];
      //     }
      //     this.isQueryDone = true;
      //     this.$vs.loading.close();
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //     this.isQueryDone = true;
      //     this.$vs.loading.close();
      //   });
    },
  },
};
</script>

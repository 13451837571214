<template>
  <div class="flex flex-col gap-6 mt-3">
    <data-table
      :responseData="responseData"
      :propsParams="params"
      :header="header"
      @reloadDataFromChild="reloadData"
    >
      <template slot="tbody">
        <vs-tr :key="index" v-for="(tr, index) in responseData.records">
          <vs-td>
              <vx-tooltip text="Edit" class="flex justify-center">
                  <vs-button
                      color="primary"
                      type="line"
                      icon-pack="feather"
                      icon="icon-edit"
                      @click="view(tr)"
                  />
              </vx-tooltip>
          </vs-td>
          <vs-td>
              {{ tr.code }}
          </vs-td>
          <vs-td>
              <p>ID : {{ tr.supplier_code }}</p>
              <p>Name : {{ tr.supplier_name }}</p>
          </vs-td>
          <vs-td>
            <template v-for="(tr_l, i_l) in tr.credit_note_supplier_line">
              <p class="mb-1" v-if="tr_l.customer_code && tr_l.value_debit > 0" :key="'customer-line-' + i_l">
                  Customer Number : {{ tr_l.customer_code }} <br/>
                  Customer Name : {{ tr_l.customer_name }}
              </p>
            </template>
          </vs-td>
          <vs-td>
              {{ tr.reference }}
          </vs-td>
          <vs-td>
            <template v-for="(tr_l, i_l) in tr.credit_note_supplier_line">
              <!-- <vs-divider
                  :key="'devider-line-' + i_l"
                  v-if="tr_l.value_credit > 0 && i_l > 0"
              /> -->
              <p
                  :class="i_l > 0 ? 'bt pt-1' : ''"
                  v-if="tr_l.customer_code && tr_l.value_debit > 0"
                  :key="'coa-line-' + i_l"
              >
                  COA: {{ tr_l.chart_of_account_name }}
              </p>
              <p class="mb-1" v-if="tr_l.customer_code && tr_l.value_debit > 0" :key="'credit-line-' + i_l">
                  Value Debit: {{ formatPrice(tr_l.value_debit) }}
              </p>
              <p class="mb-1" v-if="tr_l.customer_code && tr_l.value_debit > 0" :key="'value-settled-'+i_l">
                Value Settled: {{ formatPrice(tr_l.value_settled) }}
              </p>
            </template>
          </vs-td>
          <vs-td>
              <p>Document Date : {{ dateFormat(tr.date) }}</p>
              <p>Posting Date : {{ dateFormat(tr.posting_date) }}</p>
          </vs-td>
        </vs-tr>
      </template>
    </data-table>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    territoryIDs: Array,
    dcIDs: Array,
    selectedCustomer: Object,
    selectedDate: Object,
    isQueryDone: Boolean,
    invoiceCodes: Array,
    isFilter: Boolean,
    selectSupplier: Object,
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "id",
      },
      header: [
        {
          text: "Action",
          // value: "code",
          // width: '5%'
        },
        {
          text: "CN Supplier ID",
          sortable: false,
        },
        {
          text: "Supplier Data",
          sortable: false,
        },
        {
          text: "Customer",
          sortable: false,
        },
        {
          text: "Reference",
          sortable: false,
        },
        {
          text: "COA/Value",
          sortable: false,
        },
        {
          text: "Date",
          sortable: false,
        },
      ],
      responseData: {},
      checkedAll: false,
      clientCodes: [],
      checked: [],
    };
  },
  mounted() {
    this.reloadData(this.params)
  },
  watch: {
    checkedAll() {
      this.checkAll();
    },
    isFilter() {
      if (this.isFilter) {
        this.reloadData(this.params);
      }
    },
    // territoryIDs() {
    //   this.reloadData(this.params);
    // },
    // dcIDs() {
    //   this.reloadData(this.params);
    // },
    // selectedCustomer() {
    //   this.reloadData(this.params);
    // },
    // selectedDate() {
    //   this.reloadData(this.params);
    // },
    // invoiceCodes() {
    //   this.reloadData(this.params);
    // },
  },
  methods: {
      view(selected) {
          return this.$router.push({
            name: "customer-deposit-settlement.detail.view",
            params: { selected: selected, id: btoa(selected.ID) },
          });
      },
    reloadData(params) {
      this.params = params;
      console.log(this.selectSupplier, "this.selectSupplier");
      let supplierID = this.selectSupplier.value ? this.selectSupplier.value.id : 0;
      console.log(params);
      // console.log(
      //   "getters",
      //   this.$store.getters["operatingUnit/getSelectedTerritoryId"]
      // );
      // const territory_ids =
      //   this.$store.getters["operatingUnit/getSelectedTerritoryId"];
      // let codes = this.invoiceCodes;
      // if (this.invoiceCodes.length > 0) {
      //   codes = this.invoiceCodes.map((data) => data.code);
      // }
      this.$vs.loading();
      this.$http
        .get("api/v2/finance/credit-note-supplier/settlement", {
          params: {
            search: params.search,
            length: params.length,
            page: params.page,
            order: params.order,
            sort: params.sort,
            supplier_id: supplierID,
            territory_ids: this.territoryIDs,
            is_settled: true,
          },
        })
        .then((resp) => {
          console.log(resp);
          if (resp.code == 500) {
            this.$vs.loading.close();
          } else if (resp.code == 200) {
            this.$vs.loading.close();
            this.responseData = resp.data;
          //   this.responseData.length = resp.data.total_record_per_page;
          //   this.responseData.total_record = resp.data.total_record;
          //   this.responseData.total_search = resp.data.total_record_search;
            console.log(this.responseData.records, "Total record: ");
            // this.checkedAll = false;
          } else {
            this.$vs.loading.close();
          }
        });
    },
    check(val, index) {
      if (this.checked.includes(val)) {
        this.checked.splice(this.checked.indexOf(val), 1);
        const codeIndex = this.clientCodes.findIndex((v) => v.id === val);
        this.clientCodes.splice(codeIndex, 1);
      } else {
        this.checked.push(val);
        const obj = {
          id: val,
          code: this.responseData.invoices[index].invoice.client_code,
        };
        this.clientCodes.push(obj);
      }

      console.log(this.clientCodes, "this.checked");
    },
    dateFormat(value) {
      return moment(value).format("DD-MM-YYYY");
    },
    formatPrice(angka, prefix = "") {
      let number = parseFloat(angka).toFixed(2);
      return number
          .toString()
          .replace(/,/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    dateFormatApi(value) {
      return moment(value).format("YYYY-MM-DD");
    },
    checkAll() {
      if (this.checkedAll) {
        this.checked = this.responseData.invoices.map((v) => v.invoice.ID);
        this.clientCodes = this.responseData.invoices.map((v) => ({
          id: v.invoice.ID,
          code: v.invoice.client_code,
        }));
      } else {
        this.checked = [];
        this.clientCodes = [];
      }
      // console.log(this.clientCodes);
    },
    getTerritoryName(territoryID) {
      const territory = [...this.$store.state.operatingUnit.filter.territory];
      const currentTerritory = territory.find(
        (v) => v.TerritoryID === territoryID
      );
      return currentTerritory
        ? `${currentTerritory.TerritoryCode} - ${currentTerritory.TerritoryName}`
        : "";
    },
    createInvoiceExchange() {
      if (this.checked.length == 0) {
        this.$vs.notify({
          title: "Invoice exchange creation",
          text: "Please select at least one invoice",
          position: "top-right",
          color: "danger",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return;
      }

      for (let i = 0; i < this.clientCodes.length; i++) {
        if (this.clientCodes[0].code !== this.clientCodes[i].code) {
          this.$vs.notify({
            title: "Invoice exchange creation",
            text: "Please select invoices with the same customer",
            position: "top-right",
            color: "danger",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          return;
        }
      }
    },
  },
};
</script>
